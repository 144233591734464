// @import "../../../assets/css/globalStyle.scss";

.faq-container {
  width: 60%;
  margin: 0 auto;

  .faq-items-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 3rem;
  }
.sub-faq-title{
  font-size: 28px;
  text-align: center;
}
  .faq-title {
    font-size: 37px;
    line-height: 45px;
    color: #0a0a0a;
    text-align: center;
    width: fit-content;
    margin: 3rem auto 1rem auto;
    text-transform: uppercase;
  }

  .faq-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;

    .faq-question {
      font-size: 18px;
      cursor: pointer;
      color: #007bff;
      transition: color 0.3s;

      &:hover {
        color: #0056b3;
      }
    }

    .faq-answer {
      margin-top: 10px;
      font-size: 16px;
      color: #555;
    }
  }
}

@media (max-width: 768px) {
  .faq-container {
    width: 95%;
    padding: 15px;

    .faq-title {
      font-size: 20px;
    }

    .faq-item {
      .faq-question {
        font-size: 16px;
      }

      .faq-answer {
        font-size: 14px;
      }
    }
  }
}
