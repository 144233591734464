$item-color: #B0B0B0;

.layout-container{
    .restaurant-nav {
        display: none;
    }

    .restaurant-bottom-nav-mobile{
       width: 100%;
       height: 60px;
       padding: 15px 25px;
       background: #292D32;
       position: fixed;
       bottom: 0;
       z-index: 99999;
       border-top-right-radius: 20px;
       border-top-left-radius: 20px;

        .nav-container-bottom-mobile{
            display: flex;
            column-gap: 15px;
            ul{
                list-style: none;
                width: 75%;
                margin: auto;
                display: flex;
                justify-content: space-between;
                padding-left: 0;
                font-size: 22.5px;
                align-items: center;

                li {
                    a{     
                        color: $item-color;               
                        &:hover{
                            cursor: pointer;
                            color: white;
                        } 
                        &.active {
                            color: white;
                        }
                    } 
                    // 'profile' nav item mobile view
                    .nav-btn {
                        padding: 0 !important;
                        background-color: transparent;
                        border: none; 
                        color: $item-color;   
                    }
                    .nav-btn-active{
                        padding: 0 !important;
                        background-color: transparent;
                        border: none; 
                        color: white;
                    }

                }
            }
            .btn-container {
                button{
                    background: transparent;
                    border: none;
                    color: $item-color;
                    font-size: 20px;
                    &:hover{
                      cursor: pointer;
                      color: white;
                    }
                }
            }
        }
    }

    main{
        // background: white;
        height: 100vh;
        width: 100vw;
        margin-top: 0;
        position: fixed;
        z-index: 999;
        float: right;
    }

    .profile-dropdown {
        padding: 10px;
        height: 105px;
        margin-bottom: 0 !important;
        position: absolute;
        bottom: 70px;
        right: 30px;
        color: white;
        background: #292D32;
        border-radius: 5px;
        z-index: 70;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        ul{
            padding: 0;
            li{
                list-style: none;
                padding: 7px 10px;
                a{
                    
                    border-radius: 3px;
                    font-size: 13px;
                    letter-spacing: .5px;
                    color: $item-color;
                    &:hover{
                        background: #35383d56;
                        color: white;
                        cursor: pointer;
                    }
                }
                &:hover{
                    background: #35383d56;
                }
            }
        }
        .caret-left{
            position: absolute;
            bottom: -12px;
            right: 42%;
            margin: auto;
            font-size: 25px; 
            transform: rotate(-90deg);
        }
    }

    .logout-modal {
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: 999;
        background: #9f9f9f7a;
        backdrop-filter: blur(3px);

        .logout-modal-sub {
            min-width: 280px;
            width: 25%;
            max-width: 25%;
            margin: 15% auto 0 auto;
            border-radius: 12px;
            background: #fff;
            padding: 15px;
            display: grid;
            row-gap: 10px;

            p {
                text-align: center;
            }

            .logout-btn-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                column-gap: 50px;
                row-gap: 20px;

                div {
                    padding: 0.5rem 2.5rem;
                    border-radius: 5px;
                    &:hover {
                        cursor: pointer;
                    }
                }
                .logout-btn {
                    background: rgb(206, 50, 50);
                    color: white;
                }
                .cancel-logout-btn {
                    background: #cdcdcd;
                    color: black;
                }
            }
        }
    }

}




// Desktop view 
@media screen and (min-width: 850px){
    .layout-container{
        position: relative;
        .restaurant-bottom-nav-mobile{
            display: none;
        }

        .restaurant-nav {
            display: block !important;
            height: 100vh;
            width: 124px;
            position: fixed;
            z-index: 99;
            left: 0;
            background: #292D32;
            // z-index: -1;
        
            .nav-container{ 
                height: 85%;
                display: grid;
                row-gap: 50px;
                text-align: center;
                align-items: center;
                padding: 10px 50px 30px 0;
        
                img{
                    margin: 0 auto;
                    width: 40px;
                    height: 40px;
                }       
                ul{
                    padding-left: 0;
                    list-style: none;
                    display: grid;
                    row-gap: 15px; 
        
                    li {
                        padding: 0;
                        font-size: 27px;

                        .nav-btn {
                            padding: 0 !important;
                            background-color: transparent;
                            border: none; 
                            color: $item-color;               
                            &:hover{
                                cursor: pointer;
                                color: white;
                            } 
                            &.active {
                                color: white;
                            }
                        }
                        .nav-btn-active{
                            padding: 0 !important;
                            background-color: transparent;
                            border: none; 
                            color: white;
                        }


                        .active {
                            color: white;
                        }
                        a{     
                            color: $item-color;               
                            &:hover{
                                cursor: pointer;
                                color: white;
                            } 
                        } 
                    }
                }
                .btn-container {
                    margin: 0 auto;
                    button{
                        background: transparent;
                        border: none;
                        color: $item-color;
                        &:hover{
                          cursor: pointer;
                          color: white;
                        }
                        &:active{
                            color: white;
                        }
                    }
                }
            }
            
        }
        main{
            width: 94.8vw;
            right: 0;
            border-top-left-radius: 42px;
            border-bottom-left-radius: 42px;
            background: white;
            position: relative;
            z-index: 555;
        }

        .profile-dropdown {
            padding: 10px;
            width: 12%;
            position: absolute;
            top: 45.3vh;
            left: 5.7vw;
            .caret-left{
                // position: absolute;
                bottom: -5px;
                left: -85px;
                font-size: 25px;
                transform: rotate(90deg);
            }
        }
    }
}

