
.rider-terms-section {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    // background-color: #fff;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.rider-terms-section h1 {
    text-align: center;
    color: #333;
}

.rider-terms-section h2 {
    color: #007BFF;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}

.rider-terms-section h3 {
    color: #333;
    margin-top: 20px;
}

.rider-terms-section p {
    margin: 10px 0;
}

.rider-terms-section p strong {
    font-weight: bold;
}

.rider-terms-section a {
    color: #007BFF;
    text-decoration: none;
}

.rider-terms-section a:hover {
    text-decoration: underline;
}