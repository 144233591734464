@import '../globalStyle.scss';

.profile-edit-container{
    height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar{
        width: 4px;
    }

    .edit-button, .save-button{
        padding: 7px 30px;
        border: 1px solid $primary-color;
        background: $primary-color;
        color: #000;
        border-radius: 8px;
    }

    .nav-link{
        width: 100%;
        padding: 0 50px;
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h1{
            font-weight: 500;
            font-size: 30px;
            letter-spacing: 0.15px;
            color: $black;
        }
        
    }

    section{
        width: 35%;
        margin: 150px auto 0 auto;
        padding-bottom: 70px;

        .photo-upload {
            height: 200px;
            width: 100vw;
            border: 1px dashed $black;
            background: #D9D9D9;
            text-align: center; 
            display: table-cell;
            vertical-align: middle;
            img {
                background-size: cover;
                aspect-ratio: '16/9';
            }
        }
        .hidden-input{
            display: none;
        }
        .inputs-container{            
            margin-top: 30px;

            .show-modal{
                color: $primary-color;
                font-size: 14px;
                display: flex;
                justify-content: flex-end;
                margin-top: -15px;
                &:hover{
                    cursor: pointer;
                }
            }
            button{
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: 564px){
    .profile-edit-container{
        .edit-button{         
            padding: 5px 15px !important;
        } 
        .nav-link{
            padding: 0;
            h1{
                font-size: 25px;
            }
        }
        section{
            width: 90%;
        }
    }
}