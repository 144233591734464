@import "../../../assets/css/globalStyle.scss";

.privacy-policy-container {
  padding: 50px 30px 50px 30px;
  margin-top: 90px;

  h1 {
    font-size: 35px;
    line-height: 56px;
    font-weight: 700;
    color: $black;
    text-align: center;
    width: fit-content;
    margin: 0 auto 50px auto;
  }

  p {
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    color: $black;
  }

  .privacy-policy-section {
    max-width: 100%;
    margin: 20px auto;
    padding: 20px;
    // background-color: #fff;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .privacy-policy-section h1 {
    text-align: center;
    color: #333;
  }

  .privacy-policy-section h2 {
    color: #000;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
  }

  .privacy-policy-section p {
    margin: 10px 0;
  }

  .privacy-policy-section a {
    color: #007bff;
    text-decoration: none;
  }

  .privacy-policy-section a:hover {
    text-decoration: underline;
  }
}

@media screen and (min-width: 1024px) {
  .privacy-policy-container {
    padding: 50px 70px 50px 100px;

    h1 {
      font-size: 40px;
      line-height: 66px;
    }

    p {
      font-size: 20px;
      line-height: 30px;
    }
  }
}
