@import "../../../assets/css/globalStyle.scss";

.service-container {
    background: white;
    padding: 5rem 3rem 4rem 3rem;
    // height: 50vh

    h2 {
        font-size: 27;
        line-height: 45px;
        color: $black; 
        text-align: center;
        width: fit-content;
        margin: 0 auto 4.5rem auto;
    }

    .grid-container {
        display: grid;
        row-gap: 6rem;

        .grid-1 {
            width: 100%;
            display: grid;
            align-items: center;
            grid-template-columns: repeat(1, minmax(0, 1fr));
    
            .img {
                grid-column: span 1 / span 1;
                margin: 1rem auto 3rem auto;
                order: 1;
            }
            .text {
                grid-column: span 1 / span 1;
                order: 2;

                .header {
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    column-gap: 15px;
                    font-size: 23px;
                    line-height: 40px;
                    font-weight: 800;
                    color: #805705;
                }

                p, li {
                    font-size: 17px;
                    line-height: 30px;
                    font-weight: 300;
                    
                    b {
                        color: black;
                        font-weight: 800;
                    }
                }
            }
        }

        .grid-2 {
            width: 100%;
            display: grid;
            align-items: start;
            grid-template-columns: repeat(1, minmax(0, 1fr));
    
            .img {
                grid-column: span 1 / span 1;
                margin: 1rem auto 3rem auto;
            }
            .text {
                .header {
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    column-gap: 15px;
                    font-size: 23px;
                    line-height: 40px;
                    font-weight: 800;
                    color: #805705;
                }

                p, li {
                    font-size: 17px;
                    line-height: 30px;
                    font-weight: 300;
                    
                    b {
                        color: black;
                        font-weight: 800;
                    }
                }
            }
        }
    }
}



@media screen and (min-width: 1024px) {
    .service-container{
        padding: 6rem 7rem 4rem 7rem;

        h2 {
            font-size: 44px;
            line-height: 66px;
        }

        .grid-container {
            .grid-1.text.header, .grid-2.text.header {
                font-size: 30px;
                line-height: 45px;
            }
            .grid-1.text p, .grid-1.text ul, .grid-2.text p, .grid-2.text ul {
                font-size: 19px;
                line-height: 30px;
            }

            .grid-1 {
                grid-template-columns: repeat(5, minmax(0, 1fr));
                .img {
                    grid-column: span 2 / span 2;
                    order: 2;
                    // background: red;
                }
                .text {
                    grid-column: span 3 / span 3;
                    order: 1;
                }
            }

            .grid-2 {
                grid-template-columns: repeat(5, minmax(0, 1fr));
                .img {
                    grid-column: span 2 / span 2;
                    // order: 1;
                    // background: red;
                }
                .text {
                    grid-column: span 3 / span 3;
                    // order: 1;
                }
            }
        } 
    }
}