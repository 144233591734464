@import '../globalStyle.scss';

.sign-in-container {
    max-width: 50%;
    min-width: 300px;
    margin: auto;
    margin-top: 130px;
    padding: 50px 0;

    h1{
        text-align: center;
        color: $primary-color;
    }

    .password-group{
        input{
            border-right: none;
        }
        div{
            border-left: none;
            border: 1px solid #bdbdbd;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            background: $white;
            outline: none;
            padding: 0 5px;
            line-height: 35px;
            cursor: pointer;
        }
    }

    .fgt-psd {
        display: flex;
        justify-content: flex-end;
        color: $primary-color;
    }
    .signin-btn{
        background: $primary-color;
        border: $primary-color;
        box-shadow: rgba(99, 99, 99, 0.2) 0 8px 5px -5px;
        letter-spacing: .7px;
    }
}