// variables
$white: #ffffff;
$black: #0a0a0a;
$dark-grey: #4d4d4d;
$primary-color: #ffae0a;
// $primary-color: #ffad0a26;
$success: #4f8a10;
$error: #d8000c;
$grey-text: #d6d6d6;
// $very-liht-grey: #f7f7f7b9;

// Fonts
@font-face {
  font-family: "Lato";
  src: local("Lato"), url(../font/Lato/Lato-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Outfit";
  src: local("Outfit"), url(../font/Outfit/Outfit-Bold.ttf) format("truetype");
}

body,
html {
  margin: 0;
  font-family: "Lato";
  height: 100%;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

.error-text {
  font-size: 12.5px;
  color: $error;
}
.required-text {
  font-size: 16px;
  color: $error;
}

.success-modal-container {
  background: #191919c7;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  .modal-content {
    padding: 15px;

    .btn-container {
      display: flex;
      justify-content: right;
    }

    .modal-title {
      text-transform: capitalize;
      font-weight: 600;
      font-size: 23px;
      text-align: center;
    }
  }
}

.loader {
  border: 5px solid #f3f3f3a8;
  border-style: solid;
  border-color: #f3f3f3a8;
  border-radius: 50%;
  border-top-style: solid;
  margin-left: auto;
  margin-right: auto;
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Override the styles for TextField
.MuiInputLabel-root {
  &.Mui-focused {
    color: #ffae0a !important; // Set the color for the focused state
  }
}
.MuiOutlinedInput-root {
  &.Mui-focused {
    fieldset {
      border-color: #ffae0a !important; // Set the outline color for the focused state
    }
  }
}
// .mdb-input{
//   &:focus {
//     color: red !important;
//     outline-color: red !important;
//   }

//   label {
//     color: red !important;
//   }
//   outline-color: red !important;
// }
.md-outline input:focus {
  border-color: #ffae0a !important;
  // box-shadow: inset 0 0 0 1px red !important;
  // border-bottom: 1px solid red !important;
}

.md-outline label.active {
  color: #ffae0a !important;
}

.form-control {
  &:focus,
  &:active {
    border-color: #ffae0a !important;
    outline: #ffae0a !important;
  }
}
.input-group > .form-control {
  &:focus,
  &:active {
    border-color: #ffae0a !important;
    outline: #ffae0a !important;
    // color: #957001;
  }
}

.take-away {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 16px;

  input {
    padding: 5px;
    height: 20px;
    width: 20px;
  }
}
