@import "../../../assets/css/globalStyle.scss";

.review-container{
    background:  #FFE4AD;
    padding: 5rem 3rem 4rem 3rem;
    
    h2 { 
        font-size: 27;
        line-height: 45px;
        color: $black; 
        text-align: center;
        width: fit-content;
        margin: 0 auto 2.5rem auto;
    }
    
    .reviews-box-container{
        min-width: 330px;
        display: grid;
        margin: 0 auto;
        grid-template-columns: repeat(3, 1fr);
        overflow-x: auto;
        // row-gap: 3rem;
        column-gap: 3rem;

        ::-webkit-scrollbar {
            width: 4px;
            overflow: auto;
        }

        .review-box{
            padding: 20px;
            background: white;
            border-radius: 8px;
            min-width: 270px;

            p{                    
                font-weight: 400;
                font-size: 15px;
                line-height: 30px;
                letter-spacing: 0.25px;
                color: black;
            }
            .author{
                text-align: center;
            }
        }
    }


}


@media screen and (min-width: 1024px) {
    .review-container {
        padding: 4rem 7rem 8rem 7rem;

        h2 {
            font-size: 44px;
            line-height: 66px;
        }
        .reviews-box-container{
            column-gap: 7rem;
            .review-box{
                p{                   
                    font-size: 18px;
                    line-height: 36px;
                }
            }
        }
    }
}