@import "../../../assets/css/globalStyle.scss";

.jumbotron {
  background: $black;
  background-image: url(../../../assets/images/landing-page/landingpage-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 88.5vh;
  margin-top: 5rem;

  div {
    max-width: 80%;
    width: 80%;
    min-width: 250px;
    margin: 0 auto;
    text-align: center;
    padding-top: 4rem;
    // border: 1px solid red;

    h1 {
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: 0.25px;
      color: white;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      margin-top: 3rem;
      margin-bottom: 3rem;
      color: $grey-text;
    }
    a {
      background: $primary-color;
      color: $black;
      border-radius: 8px;
      border: 1px solid $primary-color;
      padding: 15px 40px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .jumbotron {
    margin-top: 5.5rem;
    background-size: 110%;

    div {
      padding-top: 9rem;
      h1 {
        font-size: 72px;
        font-weight: 700;
        line-height: 90px;
      }
      p {
        margin-top: 6rem;
        font-size: 20px;
      }
    }
  }
}
