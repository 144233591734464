@import "../globalStyle.scss";

.profile-container {
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 0;
  .nav-link {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-weight: 500;
      font-size: 25px;
      letter-spacing: 0.15px;
      color: $black;
    }
    .edit-button {
      padding: 5px 15px;
      border: 1px solid $primary-color;
      background: $primary-color;
      color: #000;
      border-radius: 8px;
    }
  }
  .row {
    background: white;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 100vh;
    .col1 {
      padding-left: 50px;
      img {
        margin-top: 50px;
      }
    }
    .col2 {
      background: white;
      div {
        margin-top: 50px;
        text-align: center;
        h3 {
          font-size: 16px;
          letter-spacing: 0.4px;
          color: $primary-color;
        }
        p {
          font-weight: 400;
          font-size: 20px;
          letter-spacing: 0.5px;
          color: $black;
        }
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .profile-container {
    height: 80vh;
    .nav-link {
      padding: 0 50px;
      h1 {
        font-size: 30px;
      }
      .edit-button {
        padding: 7px 30px;
      }
    }
    .row {
      height: 0;
      .col2 {
        padding-bottom: 50px;
        div {
          text-align: left;
        }
      }
    }
  }
}

.bank-details-modal {
  .modal-content {
    border-radius: 12px;

    .modal-body {
      .bank-details-container {
        .header {
          h4 {
            color: #333;
            font-size: 1.5rem;
          }
        }

        .form-group {
          label {
            color: #555;
            font-size: 0.95rem;
          }

          .form-control,
          .form-select {
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            padding: 0.7rem 1rem;
            transition: all 0.3s ease;
            background-color: #f8f9fa;

            &:focus {
              border-color: #ffae0a;
              box-shadow: 0 0 0 0.2rem rgba(255, 174, 10, 0.15);
            }
          }

          .form-select {
            cursor: pointer;
            text-transform: capitalize;

            &:hover {
              background-color: #f0f0f0;
            }

            option {
              padding: 10px;
              text-transform: capitalize;
            }
          }
        }

        .btn {
          border-radius: 8px;
          padding: 0.6rem 1.5rem;
          font-weight: 500;
          transition: all 0.3s ease;
          min-width: 120px;

          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }

          .spinner-border {
            width: 1rem;
            height: 1rem;
            border-width: 0.15em;
          }

          &.btn-primary {
            background-color: #ffae0a;
            border-color: #ffae0a;

            &:hover:not(:disabled) {
              background-color: darken(#ffae0a, 5%);
              border-color: darken(#ffae0a, 5%);
            }
          }

          &.btn-secondary {
            background-color: #f5f5f5;
            border-color: #e0e0e0;
            color: #666;

            &:hover:not(:disabled) {
              background-color: #e8e8e8;
              border-color: #d5d5d5;
            }
          }
        }
      }
    }
  }
}

.bank-details-section {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  h3 {
    color: $primary-color;
    font-size: 16px;
    letter-spacing: 0.4px;
    margin: 0;
  }

  .edit-button {
    padding: 5px 15px;
    border: 1px solid $primary-color;
    background: $primary-color;
    color: #000;
    border-radius: 8px;
    font-size: 14px;
    transition: all 0.3s ease;

    &:hover {
      background: darken($primary-color, 5%);
    }
  }

  .bank-info {
    p {
      margin: 0;

      &.text-muted {
        font-size: 14px;
      }

      &.fw-semibold {
        font-size: 16px;
        color: $black;
      }
    }
  }
}
