.notification-container {
    margin-top: 30px;

    .container-body {
        height: 84vh;

        .notification-list {
            height: 82vh;
            overflow-y: auto;
            margin-top: 10px;
            
            ul {
                list-style: none;
                padding-left: 0;
                padding-right: 10px;

                li {
                    display: flex;
                    column-gap: 10px;
                    padding: 7px 10px;

                    .icon {
                        background: #ebebeb;
                        padding: 7px;
                        border-radius: 7px;

                        img {
                            height: 25px;
                            width: 25px;
                            border-radius: 7px;
                        }
                    }
                    .text {
                        p { 
                            padding: 0;
                            margin: 0;
                        }
                        .title {
                            font-weight: bolder;
                            font-size: 14.5px;
                            color: black;
                            font-stretch: extra-condensed;
                        }
                        .body {
                            font-size: 14px;
                        }
                    }
                    &:hover {
                        background: #ebebeb58;
                        border-radius: 7px;
                        cursor: pointer;
                    }
                }
            }
        }

        .notification-detail {
            border-left: 1px solid #ebebeb;
            padding: 50px 30px;

            .date {
                font-size: 14px;
                text-align: right;
            }
            .detail-title {
                font-size: 30px;
                font-weight: bolder;
                margin-top: 30px;
            }
            .detail-body {
                font-size: 18px;
                font-weight: lighter;
            }
        }
    }

    .empty-notification {
        img {
            display: flex;
            justify-content: center;
            width: 35%;
            min-width: 200px;
            height: 300px;
            margin: 30px auto 0 auto;
        }
        p {
            text-align: center;
            font-size: clamp(15px, 3rem, 25px);
            font-weight: bolder;
        }
    }
}

// Desktop view 
@media screen and (min-width: 850px){
    .notification-container {
        padding: 30px 30px 30px 50px;
        .container-body {
            display: grid;
            grid-template-columns: 60% 40%;

            .notification-list {
                ul {
                    li {
                        align-items: center;
                    }
                }
            }
        }
        // .detail-overlay {
        //     display: none;
        // }
    }
    .detail-overlay {
        display: block;
        position: fixed;
        background: #ebebeb21;
        height: 100%;
        width: 100%;
        z-index: 99999999;
    }
}



@media screen and (max-width: 849px){
    .notification-container {
        .container-body {
            display: grid;

            .notification-list {
                ul {
                    li {
                        align-items: start;
                    }
                }
            }
            .notification-detail {
                display: none;
            }
        } 
     
    }
    .detail-overlay {
        display: block;
        position: fixed;
        top: 0;
        background: #ebebeb53;
        backdrop-filter: blur(0) brightness(0.5) contrast(100%) grayscale(0%) hue-rotate(0deg) invert(0%) opacity(1) saturate(100%) sepia(0%);
        height: 100vh;
        width: 100%;
        z-index: 999;

        .detail-container {
            background: white;
            width: 90%;
            border-radius: 7px;
            padding: 15px;
            margin: 40% auto 0 auto;

            .close {
                float: right;
                font-size: 18px;
                &:hover {
                    cursor: pointer;
                }
            }
            .detail {
                clear: both;

                .date {
                    font-size: 14px;
                    margin-bottom: 0;
                }
                .detail-title {
                    font-size: 20px;
                    text-align: center;
                    font-weight: bolder;
                    margin-top: 20px;
                }
                .detail-body {
                    font-size: 14px;
                    font-weight: lighter;
                }
            }
        }
    }
}