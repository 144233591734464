@import "../../../assets/css/globalStyle.scss";
.meals-categories-container {
  padding: 50px 0px 50px 0px;
  margin-top: 90px;
  h4 {
    font-size: 35px;
    line-height: 56px;
    font-weight: 700;
    color: #000;
    text-align: center;
    width: fit-content;
    // border: 1px solid red;
    margin: 0 auto 50px auto;
  }
  .container {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    //   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(2, 40%);
    justify-content: space-between;
  }

  .category {
    margin-bottom: 20px;
  }

  .category h2 {
    color: #000;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    font-size: 24px;
  }

  .category ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 10px 0;
  }

  .category li {
    margin-bottom: 5px;
  }
}
@media (max-width: 600px) {
  .category h2 {
    font-size: 18px;
  }
}
