@import '../../assets/css/globalStyle.scss';

.order-details-modal {
    .order-list {
        background: white;
        display: relative;
        z-index: 999999;
        height: 200px;
        overflow-y: auto;
        ::-webkit-scrollbar {
            width: 4px;
        }
        ul{
            padding-left: 0;
            list-style: none;
            display: grid;        
            row-gap: 15px;
    
            li {
                width: 100%;
                display: flex;
                align-items: center;
                column-gap: 10px;
    
                img{
                    height: 50px;
                    width: 50px;
                }
                .order-detail {
                    width: 100%;
                    display: grid;
    
                    .order-name-count{
                        display: flex;
                        justify-content: space-between;
                        p{
                            line-height: 10px;
                            margin-bottom: 7px;
                        }
                        .order-name{
                            width: 85%;
                            font-size: 16px;
                            font-weight: bold;
                            text-transform: capitalize;
                            word-break: break-all;
                            letter-spacing: .9px;
                        }
                    }
                    
                    .item-price {
                        font-size: 16px;
                        color: $success;
                    }
                }
            }
        }
    }
    
    .btn-container {
        display: flex;
        justify-content: center;
        column-gap: 10px;
        margin-top: 35px;

        .accept-btn{
            width: fit-content;
            background: $primary-color;
            color: $white;

        }
        .decline-btn{
            width: fit-content;
            background: $white;
            color: $primary-color;
            border: 1px solid $primary-color;
        }
    }

    .customer-note {
        display: grid;
        text-align: center;
        justify-content: center;

        h3{
            font-size: 22px;
            margin-bottom: 15px;
        }
        p{
            text-align: justify;
            letter-spacing: .8px;
        }
        
    }

    .rider-info-container {
        display: grid;
        text-align: center;
        justify-content: center;

        h3{
            font-size: 22px;
            margin-bottom: 15px;
        }
        h4 {
            font-size: 17px;
            font-weight: bold;
            margin-bottom: 5px;
            margin-top: 7px;
            letter-spacing: .8px;
        }
    }
}

.modal-container {
    .close-btn{
        background: $white;
        border: none;
        font-size: 20px;
        font-weight: bold;
    }
}