@import "../../assets/css/globalStyle.scss";

.menu-card-container {
  width: 250px;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: 32% 62%;
  column-gap: 10px;
  padding: 10px 8px;
  border-radius: 3px;

  .img-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 95px;
      border: 1px solid #adadad;
    }
    span {
      position: absolute;
      top: 0;
      left: 0;
      background-color: black;
      color: white;
      font-size: 12px;
      padding: 2px 5px;
      // width: 100%;
      // height: 100%;
    }
  }

  .detail-container {
    .menu-detail {
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .desc {
        font-size: 13.5px;
        letter-spacing: 0.4px;
      }
      h4 {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 100%;
        color: $black;
        text-transform: capitalize;
      }

      .price {
        font-size: 15px !important;
      }
    }
    .edit-delete-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      .action-icon {
        position: relative;
        svg {
          cursor: pointer;
        }
        .actions-dropdown {
          position: absolute;
          z-index: 100;
          // top: 20px;
          // left: 10px;
          background-color: white;
          color: black;
          width: fit-content;
          display: flex;
          flex-direction: column;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
          padding: 5px 0px;
          // transition: all 0.55 ease;
          li {
            padding: 5px 10px;
            list-style-type: none;
            white-space: nowrap;
            cursor: pointer;
            &:hover {
              background-color: #f5f5f5;
            }
          }
          .delete {
            color: red;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .menu-card-container {
    width: auto;
  }
}
