@import '../../assets/css/globalStyle.scss';

.layout-left-pane {
    height: 100vh;
    background-image: url(../../assets/images/restaurant/auth/bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
 
    .logo-link{
        text-decoration: none;
        .logo{
            margin-top: 45px;
            margin-left: 30px;
        }
    }
    
    .left-pane-welcome-note{
        margin: 150px auto 0 auto;
        width: 70%;
        text-align: center;

        h1, p {
            color: $white !important;
        }

        .authBtn{
            margin-top: 35px;
            display: flex;
            justify-content: center;
            a{
                background: transparent;
                color: $primary-color;
                padding: 7px 30px;
                border-radius: 5px;
                border: 2px solid $primary-color;
            }
        }
    }
}