@import '../globalStyle.scss';


.menu-manager-container{
    padding: 0 30px;
    h2{
        font-weight: 500;
        font-size: 25px;
        letter-spacing: 0.15px;
        margin-top: 35px;
    }
    .add-btns-container{
        margin-top: 40px;
        display: flex;
        gap: 20px;
        width: 100%;
        float: right;
        justify-content: flex-end;
        

        .add-item{
            border: 1px solid $primary-color;
            background: $primary-color;
            color: #000;
            box-shadow: none;
            border-radius: 8px;
            margin-left: auto;
            margin-right: auto;
        }
        .add-category{            
            border: 1px solid $primary-color;
            background: white;
            color: $primary-color;
            box-shadow: none;
            border-radius: 8px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .menu-category-container{
        margin-top: 7rem;
        height: 65vh;
        overflow-y: scroll;
        clear: both;

        &::-webkit-scrollbar{
            width: 4px;
        }

        h4{            
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.4px;
            color: $primary-color;
            margin-bottom: 15px;
        }
        .edit-category {
            border: 1px solid $primary-color;
            background: $primary-color;
            color: white;
            // box-shadow: none;
            border-radius: 8px;
        }

        .menu-manager-skeleton {
            display: flex;
            flex-direction: row;
        }

        .menu-container{
            width: 90%;
            margin-bottom: 50px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 40px;
        }
        .meal-category-dropdown {
            width: 200px;
            border-color: $primary-color;
            outline: $primary-color;
            padding: 5px 10px;
            border-radius: 5px;
            text-transform: capitalize;
            &:active, &:focus {
                border-color: $primary-color;
            }
        }
    }
}

.modal-dialog{
    h1{       
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.1px;
    }
    .buttons{
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        
        .btn-add-category{
            background: $primary-color;
        }
    }
}

.category-edit-modal {
    position: absolute;
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #191919c7;

    .delete-modal {
        background: white;
        padding: 20px;
        border-radius: 5px;
        width: 70%;
        margin: 150px auto 0 auto;

        p {
            font-size: 16px;
            b {
                font-size: 16.5px;
            }
        }
        .btn-containers {
            display: flex;
            flex-wrap: wrap;
            gap: 1.5rem;
            margin-top: 2rem;

            button {
                border-radius: 5px;
                padding: 5px 20px;
            }
            .proceed-btn {
                background: white;
                border: 2px solid $primary-color;
                color: $primary-color;
                &:hover {
                    background-color: $primary-color;
                    color: white;
                }
            }
            .cancel-btn {
                border: 2px solid #e2e2e2;
                background-color: #ebebeb;
                color: #666;
                &:hover {
                    background-color: #ebebebad;
                }
            }
    
        }
    }
}

@media screen and (min-width: 800px) {
    h2{
        font-size: 32px;
    }
    .add-btns-container{
        width: 60% !important;

        .add-item, .add-category{            
            width: 25% !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }    
    }

    .category-edit-modal {
        .delete-modal {
            width: 30%;
        }
    }
}
