@import '../globalStyle.scss';


.login-credentials-container, .restaurant-information-container, .photo-upload-container {    
    max-width: 50%;
    min-width: 300px;
    margin: auto;
    padding: 50px 0;

    h1{
        text-align: center;
        color: $primary-color;
    }
    .desc-text {
        text-align: center;
    }
    
    .next-btn{
        background: $primary-color;
        border: $primary-color;
        box-shadow: rgba(99, 99, 99, 0.2) 0 8px 5px -5px;
        float: right;
    }
    .back-btn{
        background: transparent;
        color: $primary-color;
        box-shadow: rgba(99, 99, 99, 0.2) 0 8px 5px -5px;
    }
}


// SIGN UP : LOGIN CREDENTIALS
.login-credentials-container {
    margin-top: 100px;

    .password-group{
        input{
            border-right: none;
        }
        div{
            border-left: none;
            border: 1px solid #bdbdbd;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            background: $white;
            outline: none;
            padding: 0 5px;
            line-height: 35px;
            cursor: pointer;
        }
    }

    .fgt-psd {
        display: flex;
        justify-content: flex-end;
        color: $primary-color;
    }
}



// SIGN UP: RESTAURANT INFORMATION 
.restaurant-information-container {
    margin-top: 50px;

    .add-opening-hours {
        button{
            background: $white;
            border: none;
            span{
                color: $primary-color;
            }
        }
    }
    
    .opening-days-display{
        // display: flex;
        // flex-wrap: wrap; column-gap: 15px; flex-grow: 1;
        // gap: 10px;
        // justify-content: space-between;
        
        .selected-days {
            display: flex;
            column-gap: 15px;
            flex-wrap: wrap;
            text-transform: capitalize;
            list-style: none;
            padding-left: 0;
            li{
                padding: 0 3px;
                &::after{
                    content: ','
                }
                &:last-child{
                    &::after {
                        content: ''
                    }
                }
            }
        }
    }

}





// SIGN UP: UPLOAD IMAGE 
.photo-upload-container {
    .photo-upload {
        height: 230px;
        width: 100vw;
        margin-left: auto;
        margin-right: auto;
        border: 1px dashed $black;
        background: #D9D9D9;
        text-align: center; 
        display: table-cell;
        vertical-align: middle;
        img {
            background-size: cover;
            aspect-ratio: '16/9';
        }
    }
    .remove-image {
        color: $primary-color;
        position: absolute;
        top: 21.2%;
        margin-left: -13px;
        background: rgba(128, 128, 128, 0.336)
    }
    .hidden-input {
        display: none;
    }
    .sub-text{
        margin-top: 40px;
        text-align: center;            
        font-style: normal;
        font-weight: 400;
        .text1{
            font-size: 14px;
            letter-spacing: 0.4px;
            color: $black;
            margin-bottom: 0;
        }
        .text2{
            font-size: 18px;    
            letter-spacing: 0.5px;
            color: #4D4D4D;
        }
    }
}



@media screen and (max-width: 800px) {
    .photo-upload-container{
        .remove-image{
            top: 17%;
        }
    }
}