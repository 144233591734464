@import "../../../assets/css/globalStyle.scss";

.get-started-container {
    padding:  50px 30px 50px 30px;
    margin-top: 90px;

    h1 {
        font-size: 35px;
        line-height: 56px;
        font-weight: 700;
        color: $black; 
        text-align: center;
        width: fit-content;
        margin: 0 auto 50px auto;
    }

    h2 {
        font-size: 23px;
        line-height: 37px;
        font-weight: 700;
        color: $black; 
        text-align: center;
    }

    p { 
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        color: $black; 

    }

    .content-container {
        display: grid;
        row-gap: 1rem;
        margin-bottom: 7rem;
    }

    .image-container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 5rem;
        row-gap: 1.5rem;
        justify-content: center;
    }
}

@media screen and (min-width: 1024px) {
    .get-started-container {
        padding: 50px 70px 50px 100px;

        h1 {
            font-size: 40px;
            line-height: 66px;
        }
        h2 {
            font-size: 28px;
            line-height: 42px;
            text-align: start;
        }
        p {
            font-size: 20px;
            line-height: 30px;
        }
        .image-container {
            justify-content: start;
        }

    }
    
}