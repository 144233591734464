@import "../../../assets/css/globalStyle.scss";

.terms-and-conditions-container {
  padding: 50px 0px 50px 0px;
  margin-top: 90px;

  h1 {
    font-size: 35px;
    line-height: 56px;
    font-weight: 700;
    color: $black;
    text-align: center;
    width: fit-content;
    margin: 0 auto 50px auto;
  }

  p {
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    color: $black;
  }

  .aup-section {
    // background: #fff;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  h1,
  h2,
  h3 {
    color: #333;
  }

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .aup-content h2 {
    margin-top: 20px;
    color: #555;
  }

  .aup-content p {
    margin: 10px 0;
  }

  .aup-content ul {
    list-style-type: disc;
    margin: 10px 0 10px 20px;
  }

  .aup-content ul li {
    margin: 5px 0;
  }

  .aup-content strong {
    color: #000;
  }
}

@media screen and (min-width: 1024px) {
  .terms-and-conditions-container {
    padding: 50px 70px 50px 100px;

    h1 {
      font-size: 40px;
      line-height: 66px;
    }

    p {
      font-size: 20px;
      line-height: 30px;
    }
  }
}
