@import "../../../assets/css/globalStyle.scss";

.contact-section {  
    background: white;
    padding: 5rem 3rem 4rem 3rem;

    h2 {
        font-size: 27;
        line-height: 45px;
        color: $black; 
        text-align: center;
        width: fit-content;
        margin: 0 auto 0.5rem auto;
    }

    h3{
        font-weight: 700;
        font-size: 28px;
        line-height: 42px;
        letter-spacing: 0.1px;
        color: $black;
        display: none;
    }
    p{

        font-weight: 300 !important;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.25px;
        color: $dark-grey;
        text-align: center;
    }

    .contact-info-container { 
        display: grid;
        align-items: start;
        justify-content: center;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .grid-1 {
            grid-column: span 1 / span 1;
            margin: 1rem auto 3rem auto;

            .img {
                width: fit-content;
                margin: 0 auto;
            }
        }
        .grid-2text {
            grid-column: span 1 / span 1;     
        }

        .btn-container {
            width: 100%;
        }

        button {
            background: $primary-color;
            color: $black;
            padding: 10px 40px;
            border-radius: 8px;
            border: 1px solid $primary-color;
        }
    }

}

@media screen and (min-width: 1024px) {
    .contact-section {
        padding: 6rem 7rem 4rem 7rem;

        h2 {
            font-size: 44px;
            line-height: 66px;
            margin-bottom: 4.5rem;
        }
        h3 {
            display: block;
            margin-bottom: 1.2rem;
        }
        p{
            text-align: start;
            margin-bottom: 2rem;
        }

        .contact-info-container {
            grid-template-columns: repeat(5, minmax(0, 1fr));
            .grid-1 {
                grid-column: span 2 / span 2;
                
                .img {
                    margin: 0;
                }
            }
            .grid-2 {
                grid-column: span 3 / span 3;
                // order: 1;
            }

            .btn-container {
                width: fit-content;
            }
        }
    }
}