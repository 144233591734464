@import '../globalStyle.scss';

.reviews-container-restaurant{
    background: white;
    padding: 20px 30px;
    height: 90vh;
    overflow-y: auto;
    h1{
        font-size: 30px;
        line-height: 150%;
        letter-spacing: 0.15px;
    }

    .review-row{
        flex-direction: column-reverse ;
    }

    .reviews{
        margin-top: 50px;
        padding-right: 50px;
        height: 70vh;
        overflow-y: scroll;
        &::-webkit-scrollbar{
            width: 4px;
        }
        ul{
            padding-left: 0;
            li{
                display: grid;
                list-style: none;
                border-bottom: 1px solid #adadad49;
                margin-bottom: 10px;

                h3{
                    font-size: 18px;
                    line-height: 150%;
                    letter-spacing: 0.5px;
                }
                p{       
                    font-size: 15px;
                    line-height: 150%;                    
                    letter-spacing: 0.25px;
                    color: $dark-grey;
                }

                .rating-comment-container{
                    display: grid;
                    row-gap: 10px;
                    margin: 0 auto; 
                    width: 100%;

                    .comment{        
                        overflow: wrap;
                        p{
                            font-size: 14px !important;
                        }
                    }
                }   
                .date{
                    text-align: left;
                }             
                
            }
        }

    }

    .total-review-ratings{
        margin-top: 30px;
        padding-right: 50px;
        display: grid;
        row-gap: 15px;
        background: white;
        h2{            
            font-size: 20px;
            letter-spacing: 0.1px;
            font-weight: 700;
        }
        p{
            font-weight: 700;
            font-size: 25px; 
            letter-spacing: 0.15px;
            color: $black;
        }

        .average-rating{
            .ratings-cont{
                display: flex;
                column-gap: 10px;
                align-items: center;
                .av-rating{
                    font-weight: 700;
                    font-size: 32px; 
                    letter-spacing: 0.15px;
                    color: $black;
                }
            }

            .progress-bars{
                margin-top: 20px;
                .bar-cont{
                    display: grid;
                    grid-template-columns: 5% 95%;
                    align-items: center;
                }
            }
        }
    }
}


@media screen and (min-width: 800px){
    .reviews-container-restaurant{
        height: auto !important;
        padding: 20px 50px;
        background: transparent;
        h1{
            font-size: 35px;
        }
        .review-row{
            display: flex !important;
            flex-direction: row !important;
        }
        .reviews{
            ul{
                li{
                    display: grid;
                    flex-grow: 1;
                    grid-template-columns: repeat(3,1fr);
                    .date{
                        text-align: center;
                    }
                }
            }
        }

        .total-review-ratings{
            row-gap: 30px !important;
            .average-rating{
                margin-top: 50px !important;
            }
            .progress-bars{
                margin-top: 40px !important;
            }
        }
    }
}