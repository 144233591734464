@import '../../assets/css/globalStyle.scss';

.layout-container {
    width: 100%;
    position: relative;

    nav {
        width: 100%;
        position: fixed;
        z-index: 999;
        top: 0;
        
        .nav-items {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // background: $black;
            padding: 25px 35px 25px 40px;

            .logo {
                max-width: 15%;
                width: 30%;
                min-width: 150px;
                display: none;
            }
            .logo-inverse {
                width: 100%;
                display: none;
            }
            .logo-no-text {
                display: block;
                width: 70%;
            }

            ul {
                display: none;
                column-gap: 20px;
                list-style: none;
                height:fit-content;
                margin: auto;
                li a {
                    padding: 0px 5px;
                    color: white;
                    font-weight: 400;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .request-rider-btn {
                display: none;
                border: 1px solid $primary-color;
                border-radius: 8px;
                background: transparent;
                color: $primary-color;
                padding: 8px 20px;
                font-size: 14px;
                font-weight: 400;
            }
            .menu {
                display: block;
            }
        }
    }

    .layout-main {
        height: 100vh;
        width: 100%;
        overflow-y: auto;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 99999;
        background-color: rgba(255, 229, 196, 0.027);
        backdrop-filter: blur(2px) brightness(.2);

        .drop-down {
            background: $black;
            width: 80%;
            padding: 15px 20px;
            margin: 5rem auto 0 auto;
            border-radius: 4px; /* Adjust the content background color and opacity */
            box-shadow: 0 0 3px rgba(255, 231, 200, 0.34);

            .close-btn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: white;

                img {
                    width: 30px !important;
                }
            }
            .request-rider-btn-dropdown {
                width: 100%;
                clear: both;
                margin-top: 30px;
                
                a {
                    display: flex;
                    justify-content: center;
                    padding: 10px 0;
                    background: transparent;
                    border: 1px solid $primary-color;
                    border-radius: 4px;
                    color: $primary-color;
                }
            }

        }
    }
}


@media screen and (min-width: 1024px) {
    .layout-container {
        nav .nav-items {
            padding: 25px 80px 25px 50px;

            .logo, .logo-inverse {
                display: block;
            }
            .logo-no-text {
                display: none;
            }

            ul {
                display: flex;
            }
            .request-rider-btn {
                display: block;
            }
            .menu {
                display: none;
            }
        } 
    }
}