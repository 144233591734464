@import '../globalStyle.scss';

.add-menu-container{
    height: 100vh;
    overflow-y: scroll;
    padding: 0 30px;
    &::-webkit-scrollbar{
        width: 4px;
    }

    

    .save-button{ 
        padding: 7px 30px;
        border: 1px solid $primary-color;
        background: $primary-color;
        color: #000;
        border-radius: 8px;
        margin-bottom: 100px;
    }

    h1{
        font-size: 25px;
        line-height: 150%;
        letter-spacing: 0.15px;
        color: $black;
        margin-top: 30px;
    }
    

    section{
        width: 35%;
        margin: 70px auto 0 auto;
        padding-bottom: 70px;

        .photo-upload {
            height: 200px;
            width: 100vw;
            border: 1px dashed $black;
            background: #D9D9D9;
            text-align: center; 
            display: table-cell;
            vertical-align: middle;
            img {
                background-size: cover;
                aspect-ratio: '16/9';
                margin: 0 auto;
            }
        }
        .label-texts{
            text-align: center;
            .first{
                margin-top: 5px;
                font-weight: 400;
                font-size: 15px;
                line-height: 150%;
                letter-spacing: 0.5px;
                color: $black;
            }
            .second{
                font-weight: 400;
                font-size: 13px;
                line-height: 0;
                color: $dark-grey;
                letter-spacing: 0.4px;
            }
            
        }
        .hidden-input{
            display: none;
        }
        .inputs-container{            
            margin-top: 30px;
            select{
                padding: 8px;
                border: 1px solid #bdbdbd;
                border-radius: 5px;
                margin-bottom: 30px;
                &:focus {
                    outline-color: $primary-color;
                }
            }
            .text-area{
                resize: none;
                margin-bottom: 30px;
            }
        }
    }
}

@media screen and (max-width: 564px){
    .add-menu-container{
        section{
            width: 90%;
            height: 100vh;
        }
        padding-bottom: 300px;
    }
}