@import "../globalStyle.scss";

.order-manager-container {
  .col-1 {
    background: #fafafa;
    height: 100vh;
    padding: 30px;
    h1 {
      font-size: 25px;
      // line-height: 150%;
      letter-spacing: 0.15px;
    }
    .search-group {
      margin-top: 30px;
      width: 100%;
      .search-btn {
        width: 10%;
        background: transparent;
        color: $dark-grey;
        border: 1px solid $dark-grey;
        border-right: none !important;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        box-shadow: none;
        padding: 7px 5px;
        font-size: 18px;
      }
      .search-input {
        width: 90%;
        background: transparent;
        color: $dark-grey;
        border-left: none !important;
        border: 1px solid $dark-grey;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        outline: none;
        padding: 7px 10px 7px 5px;
      }
    }
    .nav-tabs {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 50px;
      margin-top: 20px;
    }
    .nav-tabs .nav-link {
      padding: 10px;
      &.active {
        border-color: $primary-color;
        background: transparent;
        color: $black;
      }
    }
    .tab-content {
      height: 70vh;
      margin-top: 20px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 4px;
      }

      .all-orders {
        padding-left: 0;
        list-style: none;
        display: grid;
        row-gap: 20px;
        li {
          display: flex;
          justify-content: space-between;
          background: white;
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
          padding: 8px 15px;
          border-left-width: 10px;
          border-left-style: solid;

          :hover {
            cursor: pointer;
          }
          .order-header {
            h3 {
              font-size: 18px !important;
              text-transform: capitalize;
            }
            p {
              line-height: 0;
              padding-top: 10px;
            }
          }
        }
      }
    }
  }

  .col-2 {
    display: none;
  }
}

.rider-modal-container {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #191919c7;

  .rider-display {
    max-width: 40%;
    min-width: 290px;
    width: 40%;
    margin: 10% auto 0 auto;
    border-radius: 14px;
    background: white;
    padding: 20px;

    .header {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 20px;
        font-weight: 700;
        color: $primary-color;
      }
      .close-btn {
        border-radius: 50%;
        background: #1919192d;
        height: 30px;
        width: 30px;
        text-align: center;
        color: white;
        &:hover {
          cursor: pointer;
          background: #19191938;
        }
      }
    }
    .main {
      .empty {
        .lottie {
          width: 70%;
          display: flex;
          margin: 0 auto;
          position: relative;
          top: -2rem;
        }
        h4 {
          font-weight: 700;
          font-size: 20px;
          text-align: center;
          margin: 0 0 1rem 0;
        }
        .retry-btn {
          width: 50%;
          margin: 0 auto;

          div {
            button {
              width: 100%;
              background: $primary-color;
              color: $white;
              border: 1px solid $primary-color;
              padding: 7px 0;
              border-radius: 14px;
              display: flex;
              column-gap: 5px;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 850px) {
  .col-1 {
    border-bottom-left-radius: 42px;
    border-top-left-radius: 42px;

    h1 {
      font-size: 30px;
    }
  }
  .col-2 {
    display: block !important;
    padding: 0 70px 50px 30px !important;

    padding: 0 30px 50px 30px;
    height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px;
    }
    .order-details {
      h2 {
        font-size: 24px;
        color: $black;
        margin-top: 95px;
      }
      .order-details-container {
        ul {
          margin-top: 35px;
          padding-left: 0;
          list-style: none;
          height: 50vh;
          overflow: scroll;
          &::-webkit-scrollbar {
            width: 1.5px;
          }
          li {
            width: 100%;
            min-width: 400px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            padding: 8px 12px;
            background: white;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

            .item-name {
              display: flex;
              gap: 10px;
              align-items: center;
              img {
                height: 70px;
                width: 100px;
              }
            }
            .item-price {
              color: #1a9c2c;
            }
          }
        }
        .customer-note {
          h3 {
            color: $primary-color !important;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.4px;
            margin-top: 25px;
          }
        }
        .btn-container {
          display: grid;
          row-gap: 15px;
          margin-top: 50px;

          .accept-btn {
            border: 1px solid $primary-color;
            background: $primary-color;
            color: #000;
            margin-left: auto;
            margin-right: auto;
            box-shadow: none;
            width: fit-content;
          }
          .decline-btn {
            border: 1px solid $primary-color;
            background: #fff;
            color: #000;
            margin-left: auto;
            margin-right: auto;
            box-shadow: none;
            width: fit-content;
          }
        }
      }
      .rider-info-container {
        margin-top: 30px;
        h3 {
          color: $primary-color !important;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.4px;
          margin-top: 35px;
          margin-bottom: 20px;
        }
        .rider-info {
          display: flex;
          column-gap: 20px;
          margin-bottom: 50px;
          .photo-container {
            height: 80px;
            width: 80px;
            border-radius: 100%;
            border: 1px solid gray;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
          h4 {
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.5px;
            color: $black;
          }
          p {
            font-size: 16px;
            letter-spacing: 0.25px;
            color: $black;
          }
        }
      }
      .shopping-cart-gif {
        // height: 35vh;
        width: 30%;
        margin: 100px auto 20px auto;
      }
      .shopping-cart-text {
        h4 {
          font-size: 18px;
          letter-spacing: 0.65px;
          color: #333333c0;
          font-weight: bold;
          line-height: 0;
        }
      }
    }
  }
}

.wallet-balance-container {
  .wallet-icon {
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
      background-color: rgba(255, 174, 10, 0.15) !important;
    }
  }

  h3 {
    font-size: 1.75rem;
    letter-spacing: -0.5px;
  }
}
