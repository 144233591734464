@import '../globalStyle.scss';

.account-activation{
    h1{
        font-size: 26px;
        margin-top: 35px;
        padding-bottom: 30px;
    }
    label{
        font-size: 14px;
    }

    .form-btns{
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .back-btn{
            background: #fff;
            border: 1px solid $primary-color;
            color: $primary-color;
        }
        .proceed-btn{
            background: $primary-color;
        }
    }
    

    // contact information styyle block
    .contact-info-container{
        height: 62vh;
        min-width: 350px;
        width: 50%;
        max-width: 50%;
        margin: 70px auto 70px auto;
        overflow-y: auto;
        &::-webkit-scrollbar{
            width: 4px;
        }

        .social-links{
            h4{
                line-height: 0;
                margin-top: 30px;
                margin-bottom: 30px;
                font-size: 18px;
                font-weight: 600;
            }
            .links-container{
                display: grid;
                row-gap: 40px;
                .flex-container{
                    display: flex;
                    flex-wrap: wrap;
                    row-gap: 40px;
                    column-gap: 2%;
                    input{
                        width: 49%;
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        border-bottom: 1px solid #bdbdbd;
                        padding: 7px 15px;
                        outline: none;
                        &.active{
                            outline: none;
                        }
                    }
                }
            }           
        }

        .proceed-btn{
            background: $primary-color;
            margin-top: 50px;
            float: right;
            clear: both;
            margin-bottom: 30px;
        }
    }

    // business information style block 
    .business-information{
        height: 62vh;
        min-width: 350px;
        width: 55%;
        max-width: 55%;
        margin: 50px auto 70px auto;
        overflow-y: auto;
        &::-webkit-scrollbar{
            width: 4px;
        }
        .biz-cont, .bill-cont, .paym-cont{
            margin-bottom: 30px;
        }

        h4{
            font-weight: bold;
            font-size: 16px;
            color: #333 !important;
        }

        .collapse-btn{
            display: flex;
            justify-content: space-between;
            padding: 3px 0px;
            border-bottom: 1px solid #bdbdbd;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            &:hover{
                cursor: pointer;
                background: #ebebeb3a;
            }
        }

        
    }

    // contract container styling 
    .contract-container{
        min-width: 350px;
        width: 50%;
        max-width: 50%;
        margin: 70px auto 70px auto;
        overflow-y: auto;
        &::-webkit-scrollbar{
            width: 4px;
        }

       
    }

    // activation code block 
    .activation-code {
        padding: 30px 0;
        height: 50vh;
        min-width: 350px;
        width: 55%;
        max-width: 55%;
        margin: 70px auto 70px auto;
        overflow-y: auto;
        &::-webkit-scrollbar{
            width: 4px;
        }

        .t_c_block {
            display: flex;
            column-gap: 5px;
            align-items: center;
            label{
                font-size: 16px;
            }
            input{                
                accent-color: #c18102;
            }
        }
        .sign-block{
            font-size: 16px;
            .signatory-input{
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 1px solid #dbdbdb;
                outline: none;
            }
            .signatory-input-error{
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 1px solid $error;
                outline: none;
            }
        }
    }
}

.account-activation-overlay {
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.299);
    
    position: fixed;
    z-index: 1095;
}



@media screen and (max-width: 800px) {

     .links-container{
        .flex-container{
            input{ 
                width: 100% !important;
            }
        }
    }
}

