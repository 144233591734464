@import "../../../assets/css/globalStyle.scss";

.about-us {
    background: white;
    padding: 5rem 3rem 4rem 3rem;
    // height: 50vh

    h2 {
        font-size: 27px;
        line-height: 45px;
        color: $black; 
        text-align: center;
        width: fit-content;
        margin: 0 auto 4.5rem auto;
    }

    .grid-container {
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .img {
            grid-column: span 1 / span 1;
            margin: 1rem auto 3rem auto;
        }
        .text {
            grid-column: span 1 / span 1;
            p {
                font-size: 17px;
                line-height: 30px;
                font-weight: 300;
            }
        }
    }

}


@media screen and (min-width: 1024px) {
    .about-us {
        padding: 6rem 7rem 4rem 7rem;

        h2 {
            font-size: 44px;
            line-height: 66px;
        }

        .grid-container {
            grid-template-columns: repeat(5, minmax(0, 1fr));
            .img {
                grid-column: span 2 / span 2;
                // background: red;
            }
            .text {
                grid-column: span 3 / span 3;

                p {
                    font-size: 19px;
                    line-height: 30px;
                }
            }
        } 
    }
}