@import './globalStyle.scss';

// Navbar
nav {
    background: $white;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5;

    .nav-landing{
        padding: 20px 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        // Desktop View Navlinks
        .links-container-desktop{
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    
            .scroll-links {
                width: 55%;
                ul{ 
                    text-transform: uppercase;
                    padding-left: 0;
                    display: flex;
                    flex-direction: row;
                    margin-top: 20px;
                    justify-content: space-around;
                    li{
                        list-style: none;
                        color: $black;
                        cursor: pointer;
                        a{
                            &:hover, &:active, &:focus, &:target{
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
            .request-rider-link{
                display: flex;
                align-items: center;
                a{
                    text-decoration: none;
                    padding: 10px 30px;
                    background: $primary-color;
                    color: $black;
                    border-radius: 8px;
                }
            }
        }

        // Mobile View Navlinks
        .links-container-mobile {
            margin-top: 10px;
            a{
                text-decoration: none;
                color: $primary-color;
            }
        }
     
    }

    
    .mobile-dropdown-nav-opened {
        width: 100%;
        // height: 200px;
        position: absolute;
        left: 0;
        z-index: 99;
        opacity: 1;
        margin-top: 9.5rem;
        transition: margin-top 300ms, opacity 300ms;

        background: #f8f8f8;
        padding: .7rem .5rem 1.5rem .5rem;

        li {
            list-style: none;
            padding: 6px 1rem;
            border-radius: 3px;
            font-size: 14.5px;
            &:hover {
                background: color-mix(in srgb, #c4c4c4 30%, white 70%);
                cursor: pointer;
            }
        }
    }
    .mobile-dropdown-nav-closed {
        position: absolute;
        left: 0;
        opacity: 0;
        margin-top: 0;
    }
}

// Buttons
.app-btn{
    a{
        text-decoration: none;
        border-radius: 8px;
        padding: 15px 30px;
        font-size: 18px;
        letter-spacing: 0.5px;
    }
}


.app-store, .play-store{    
    text-decoration: none;
    padding: 15px 15px;
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
    column-gap: 10px;
    border: 2px solid $primary-color;
    border-radius: 8px;
    
    img {
        margin: auto;
    }

    p{
        font-size: 18px !important;
        line-height: 18px !important;
        color: $black !important;
        letter-spacing: 0.4px;
        margin: 0;
    }
    
    h3{
        font-size: 23px;
        letter-spacing: 0.5px;
    }
    
}


// Footer
footer {
    background: $black;
    color: $white !important;
    padding: 50px 116px;

    .grid{
        display: grid;
        grid-template-columns: auto auto;
        row-gap: 30px;
    }
    
    h4{
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 150%;
        letter-spacing: 0.4px;
    }
    p{        
        margin-top: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.4px;
        color: $white !important;
    }

    .location, .call-us, .socials {
        margin-top: 30px;
    }

    .socials {
        ul{
            list-style: none;
            padding: 0;
            min-width: 100px;
            width: 100px;
            display: flex;
            justify-content: space-between;

            li{
                display: inline;
                a{
                    text-decoration: none;
                }
            }
        }
    }

    .subscription-grid{
        display: grid;
        justify-content: center;

        .email-sub-container{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .email-textbox {
                background: transparent;
                padding: 10px 50px 10px 20px;
                border: .5px solid $white;
                border-radius: 3px;
                color: $white;
                outline: none;
            }
            .subscribe {
                padding: 8px 16px;
                border: none;
                background: $primary-color;
                border-radius: 3px;
    
                color: $white;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
    
                letter-spacing: 0.4px;
            }
        }
        

        .external-links {
            margin-top: 160px;
            ul{
                list-style: none;
                padding: 0;

                li{
                    display: inline;
                    a{
                        text-decoration: none;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 150%;
                        letter-spacing: 0.4px;

                        color: $white;
                    }
                }
            }
        }
        .copyright{
            font-size: 10px !important;
            margin-left: 80px;
        }
    }
}







@media screen and (max-width: 800px) {
    nav{
        .nav-landing{
            padding-left: 15px;
            padding-right: 30px;
        }
    }

    .app-store, .play-store{           
        p{
            font-size: 13px !important;
        }
        
        h3{
            font-size: 16px;
        }
        img{
            width: 35px;
        }
    }

    footer{
        padding-left: 0px;
        padding-right: 0px;
        .grid{            
            width: 50%;
            grid-template-columns: 100%;
            margin: 0 auto;
        }
        .copyright{
            width: 100%;
            margin-left: 0 !important;
        }
    }
}




// Business Hours Selection Modal 

.day-time-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 9999999999999999;
    background-color: rgba(0, 0, 0, 0.35);

    .day-time-modal-container {
        max-width: 500px;
        width: 97%;
        margin: 5% auto;
        background: white;
        border-radius: 10px;
        padding: 10px 20px 30px 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        .close-btn{
            background: none;
            border: 0;
            float: right;
            font-size: 25px;
        }
        .flex-box {
            clear: both;
            // position: relative;

            h2 {
                font-size: 25px;
                text-align: center;
                font-weight: 700;
                color: $primary-color;
            }
            p {
                padding: 7px 0;
                text-align: center;
                border-top: 1px solid #ececec;
                border-bottom: 1px solid #ececec;
            }

            .body {
                display: grid;
                row-gap: 10px;
                padding-bottom: 15px;
                border-bottom: 1px solid #ececec; 

                .row {
                    width: 100%;
                    margin: 0 auto;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    // grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));

                    .day-input-group{
                        display: flex;
                        align-items: center;
                        gap: 10px;
    
                        input {
                            height: 20px;
                            width: 20px;
                            accent-color: $primary-color;
                        }
                    }

                    .time-box {
                        display: grid;
                        column-gap: 10px;
                        grid-template-columns: repeat(2, 1fr);

                        select {
                            padding: 5px;
                            outline: $primary-color;
                            // width: 80%;
                        }
                    }
                }
                
            }
            .btn-container {
                display: flex;
                justify-content: end;

                .add-restaurant-info-btn {
                    background: $primary-color;
                    border: none;
                    padding: 7px 30px;
                    margin-top: 15px;
                    color: white;
                    border-radius: 8px;
                    
                
                    &:hover{                
                        background: #ffad0ad0;
                    }
                }
            }
        }
    }
}

// .modal-container{
//     .btn-close{
//         position: relative;
//         z-index: 5;
//     }

//     .modal-body {
//         .select-days, .select-time{
//             h3{
//                 font-size: 16px;
//                 letter-spacing: 0.4px;
//                 color: $primary-color;
//             }
//         }
        
            
//         .select-days{
//             .input-group-container{ 
//                 margin-top: 20px;
//                 display: grid;
//                 gap: 20px;

//                 .day-input-group{
//                     display: flex;
//                     align-items: center;
//                     gap: 10px;

//                     input {
//                         height: 24px;
//                         width: 24px;
//                     }
//                 }
//             }
//         }

//         .select-time{
//             .opening-time-input-group, .closing-time-input-group {
//                 display: flex;
//                 gap: 10px;
//                 align-items: center;
//                 margin-top: 20px;

//                 .time-picker__select{
//                     -webkit-appearance: none;
//                     -mod-appearance: none;
//                     appearance: none;
//                     font-size: 30px;
//                     padding: 12px 18px;
//                     border-radius: 5px;
//                     border: 2px solid black;
                    
//                 }
//                 span{
//                     font-size: 40px;
//                     color: black;
//                     font-weight: 800;
//                 }
//                 .btn-container{
//                     display: grid;
                    
//                     .am-btn, .pm-btn {
//                         border: 1px solid black;
//                         background: $white;
//                     }
//                     .am-btn{                    
//                         border-top-left-radius: 5px;
//                         border-top-right-radius: 5px;
//                         border-bottom: none;
//                         padding: 5.5px 12px;
//                     }                
//                     .pm-btn{
//                         border-bottom-left-radius: 5px;
//                         border-bottom-right-radius: 5px;
//                         padding: 5.5px 12.5px;
//                     }
//                     .selected {
//                         background: $primary-color;
//                         color: $white;
//                     }
//                 }
//             }
//         }
//     }

//     .add-restaurant-info-btn {
//             background: $primary-color;
//             &:hover{                
//                 background: #ffad0ad0;
//             }
//     }
// }






